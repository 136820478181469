import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { AppDispatch, RootState } from '../store';
import { Color } from "./../models/Color";
import { setColors, setHex, setSecondaryColors, setUpdateFlag } from '../features/productSlice';
import { fetchColorsAsync } from '../features/colorSlice';
import '../styles/ColorPalette.scss';
import { Tooltip, Button } from "@material-tailwind/react";


interface ColorPaletteProps {
    title: string;
    numOfColors: number;
    colorList: Color[];
    fromSecondary: boolean;
    changeColor: (data: string[], fromSecondary: boolean) => void;
}

const ColorPalette: React.FC<ColorPaletteProps> = ({ title, numOfColors, colorList, fromSecondary, changeColor }) => {
    const extraPrice = 5

    const dispatch = useDispatch<AppDispatch>();
    const selectedColors = useSelector((state: RootState) => state.products.colors)
    const secondaryColors = useSelector((state: RootState) => state.products.secondaryColors)
    const selectedHex = useSelector((state: RootState) => state.products.hex)
    
    useEffect(() => {
        changeColor(selectedColors, fromSecondary)
    }, [selectedColors])

    const handleCircleClick = (color: string, hex: string) => {
        
        
        
        if (fromSecondary) {
            dispatch(setUpdateFlag(false))
        } else {
            dispatch(setUpdateFlag(true))
        }
        if (selectedColors.includes(color)) {
            dispatch(setColors(selectedColors.filter(c => c !== color)));
            dispatch(setHex(selectedHex.filter(c => c !== hex)))
        } else if (selectedColors.length < numOfColors) {
            dispatch(setColors([...selectedColors, color]));
            dispatch(setHex([...selectedHex, hex]))
        }
        if (fromSecondary && secondaryColors.includes(color)) {
            dispatch(setSecondaryColors(secondaryColors.filter(c => c !== color)))
            dispatch(setColors(selectedColors.filter(c => c !== color)));
            dispatch(setHex(selectedHex.filter(c => c !== hex)))
        } else if (fromSecondary && secondaryColors.length < numOfColors) {
            dispatch(setSecondaryColors([...secondaryColors, color]))
            dispatch(setColors([...selectedColors, color]));
            dispatch(setHex([...selectedHex, hex]))
        }
    };

    const renderColorRows = (paletteList: { name: string, hexCode: string, in_stock: boolean }[]) => {
        const colorRows = [];
        colorRows.push(
            paletteList.map((color) => {
                if(color.in_stock) {
                    return(
                        <Tooltip content={color.name}>
                            <div
                                key={color.hexCode}
                                className={`h-10 w-10 rounded border border-gray-50 ${selectedHex.includes(color.hexCode) ? 'border-gray-800 border-2' : ''}`}
                                style={{ backgroundColor: color.hexCode }}
                                onClick={() => handleCircleClick(color.name, color.hexCode)}
                            ></div>
                        </Tooltip>
                    )
                } else {
                    return(
                        <Tooltip content={"Out of Stock"}>
                            <div
                                key={color.hexCode}
                                className={`h-10 w-10 rounded border-2 border-red-800 ${selectedHex.includes(color.hexCode) ? 'border-red-800 border-2' : ''}`}
                                style={{ backgroundColor: color.hexCode }}
                            ></div>
                        </Tooltip>
                    )
                }
        })
        )
        return colorRows;
    };

    const renderSelectedColors = (paletteList: { name: string, hexCode: string }[]) => {
        const colorRows = [];
        colorRows.push(
            paletteList.map((color) => (
                selectedHex.includes(color.hexCode) ? 
                <Tooltip content={color.name}>
                    <div
                        key={color.hexCode}
                        className={`h-5 w-5 rounded border border-gray-50`}
                        style={{ backgroundColor: color.hexCode }}
                        onClick={() => handleCircleClick(color.name, color.hexCode)}
                    ></div>
                </Tooltip>
                : ""
            ))
        )
        return colorRows;
    };

    return (
        <div className="color-palette">
            <div>
            <h6 className="block font-sans text-base antialiased font-semibold leading-relaxed tracking-normal text-inherit">{title}</h6>
                <div className="my-8 mt-3 flex items-center gap-2 color-palette flex-wrap">
                    {colorList ? renderColorRows(colorList) : "Loading Colors..."}
                </div>
            </div>

            {selectedHex.length > 0 ?
                <div className="my-8 mt-3 flex items-center gap-2 color-palette flex-wrap">
                    <span>Selected Colours:</span>
                    {colorList ? renderSelectedColors(colorList) : "Loading Colors..."}
                    {selectedHex.length > 4 ? <strong>{`(+$${extraPrice * (selectedHex.length - 4)}.00)`}</strong> : "" }
                </div>
            : ""}
        </div>


);
};

export default ColorPalette;
