import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { AppDispatch, RootState } from '../store';
import { Dustbin, CircleSplit, CloseIcon } from '../svgs';
import { useNavigate } from "react-router-dom";
import ButtonQuantity from './buttons/ButtonQuantity';
import useMediaQuery from '@mui/material/useMediaQuery';
import { updateQuantity, removeFromCart, getTotalPrice } from '../features/cartSlice';
import { tugStickImages, doubleHaloImages, xStickImages, snuffleBallImages, snuffleMatImages, haloImages, pomImages, daddyLongLegImages, tugAPillarImages, serpentImages } from "../common/imageMap";
import { CartItem } from "../common/types";
import '../styles/CartPage.scss';
import { Tooltip, Button } from "@material-tailwind/react";
import { fetchColorsAsync } from '../features/colorSlice';
import { allInnerArraysEmptyStrings } from "../utils/func";

const CartPage: React.FC = () => {
    let navigate = useNavigate();
    const isMobile = useMediaQuery('(max-width:700px)');
    // useSelector hook allows us to extract data from the Redux store
    const cartItems = useSelector((state: RootState) => state.cart.items);
    const finalPrice = useSelector((state: RootState) => state.cart.totalPrice)
    const colorsChosen = useSelector((state: RootState) => state.products.colors)
    const dispatch = useDispatch();
    const appDispatch = useDispatch<AppDispatch>();

    const [paletteList, setPaletteList] = useState([])

    useEffect(() => {
        const fetchColor = async () => {
            try {
                const data = await appDispatch(fetchColorsAsync())
                setPaletteList(data.payload)
            } catch (error) {
            }
        }
        fetchColor()
    }, [])

    
    

    useEffect(() => {
        dispatch(getTotalPrice())
    }, [cartItems])


    const updateQuantityHandler = (foundItem: CartItem, quantity: number) => {
        
        dispatch(updateQuantity({ foundItem, quantity, fromInput: true }));
    };

    const handleDelete = (item: CartItem) => {
        dispatch(removeFromCart(item))
    }

    const routeChange = () => {
        let path = `/checkout`;
        navigate(path);
    }

    const renderSelectedColors = (paletteList: { name: string, hexCode: string }[], selectedColors: string | string[]) => {
        const colorRows = [];
        colorRows.push(
            paletteList.map((color) => (
                selectedColors.includes(color.name) ? 
                <Tooltip content={color.name}>
                    <div
                        key={color.hexCode}
                        className={`h-5 w-5 rounded border border-gray-50`}
                        style={{ backgroundColor: color.hexCode }}
                    ></div>
                </Tooltip>
                : ""
            ))
        )
        return colorRows;
    };

    return (
        <section className="px-8 py-20">
            <div className="container mx-auto">
            <div className="mb-14 text-center ">
                <h1 className="mb-4 text-4xl !leading-snug lg:text-[40px]">Cart</h1>
            </div>
            <div className="max-w-3xl mx-auto grid gap-10">
            {cartItems.length === 0 ? (
                <p>Your cart is empty</p>
            ) : (
                <div className="cart-items-wrapper">
                    {cartItems.map(item => {
                        let totalPrice = item.quantity * item.price
                        console.log("this is cart item...", item)
                        let imageName = ''
                        if (item.item == 'Snuffle Bol') {
                            imageName = 'BOL 4.png';
                        } else if (item.item == 'Snuffle Mat') {
                            imageName = snuffleMatImages[0];
                        } else if (item.item == 'X-Stik') {
                            imageName = xStickImages[0]
                        } else if (item.item == 'Tug Stik') {
                            imageName = tugStickImages[0]
                        } else if (item.item == 'Halo') {
                            imageName = haloImages[0]
                        } else if (item.item == 'Double Halo') {
                            imageName = doubleHaloImages[0]
                        } else if (item.item == 'Daddy Long Legs') {
                            imageName = daddyLongLegImages[0]
                        } else if (item.item == 'Tug-a-pillar') {
                            imageName = tugAPillarImages[0]
                        } else if (item.item == 'Snuffle Serpent') {
                            imageName = serpentImages[0]
                        } else {
                            imageName = pomImages[0]
                        }
                        
                        return (
                            <div key={item.id} className="flex flex-row flex-wrap">
                                <div className="px-0 md:p-5 md:basis-1/6 basis-1/4	md:self-auto">
                                    <img className="snuffleball-default-image max-w-full" src={`/${imageName}`} />
                                </div>
                                <div className="px-5 md:py-5 basis-3/4 md:basis-5/6 md:flex">
                                    <div className="md:flex-inline md:basis-3/5 md:self-start">
                                        <div className="flex">
                                            <span className="product-name font-semibold text-xl !text-grey-900 grow">{item.type}</span>
                                            <button onClick={() => handleDelete(item)} className="!text-gray-500 hover:!text-gray-600 md:hidden">
                                                <CloseIcon />
                                            </button>
                                        </div>
                                        <div className="md:text-sm !text-gray-600">
                                            <span className="title">Pattern: </span>
                                            <span className="name">{item.name}</span>
                                        </div>
                                        <div className="md:text-sm !text-gray-600">
                                            <span className="title">Size: </span>
                                            <span className="name">{item.size}</span>
                                        </div>
                                        <div className="md:text-sm !text-gray-600">
                                            <span className="title">Level: </span>
                                            <span className="name">{item.level}</span>
                                        </div>
                                        <div className="md:text-sm !text-gray-600">
                                            <span className="title">Length (Additional): </span>
                                            <span className="name">{item.length} cm</span>
                                        </div>
                                        <div className="block md:flex md:gap-2 color-palette md:flex-wrap md:text-sm !text-gray-600">
                                            <span>Selected Colours: </span>
                                            <div className="flex gap-2 color-palette flex-wrap mb-3">
                                                {allInnerArraysEmptyStrings(renderSelectedColors(paletteList, item.colors)) ? "Surprise me" : renderSelectedColors(paletteList, item.colors)}
                                            </div>
                                        </div>
                                    </div>
                                    <div className="md:py-6 md:basis-1/5 block md:flex-inline md:self-start">
                                        <div className="quantity-wrapper">
                                            <ButtonQuantity
                                                id={item.id.toString()}
                                                quantity={item.quantity.toString()}
                                                onChange={(newQuantity: number) => updateQuantityHandler(item, newQuantity)}
                                            />
                                        </div>
                                    </div>
                                    <div className="small md:w-1/5 md:text-right inline md:flex-inline md:self-start">
                                            <button onClick={() => handleDelete(item)} className="!text-gray-500 hover:!text-gray-600 hidden md:inline">
                                                <CloseIcon />
                                            </button>
                                        <div className="font-bold pt-2">${(totalPrice).toFixed(2)}</div>
                                    </div>
                                </div>
                            </div>
                        )
                    })
                    }

                </div>
            )}
            <div className="checkout-wrapper w-full border-t-[1px] display-block border-gray-500/100 px-5 mt-5">
                <div className="flex my-10">
                    <div className="w-2/3">
                        <p className="font-bold">Subtotal</p>
                        <p>Taxes and shipping calculated at checkout</p>
                    </div>
                    <div className="w-1/3 text-right font-bold">
                        ${(finalPrice).toFixed(2)}
                    </div>
                </div>
                <div className="mt-4">
                    <Button disabled={false} children={<>Check Out</>} onClick={routeChange} placeholder={undefined} fullWidth />
                </div>
            </div>
        </div>
        </div>

        </section>
    );
};

export default CartPage;